import { Button, Col, Input, Row } from 'antd';
import Table, { TableProps } from 'antd/lib/table';
import { ReactElement, useCallback } from 'react';
import styled from 'styled-components';

const DataListWrapper = styled.div`
  padding: 32px;
`;

const TopLevelActions = styled.div``;

export interface DataListProps<T extends object> extends TableProps<T> {
  onCreate?: () => void;
  onSearch?: (value: string) => void;
  // onDelete?: (data: T) => void;
  // onEdit?: (data: T) => void;
}

export const DataList = function DataList<T extends object>(props: DataListProps<T>): ReactElement | null {
  const { onSearch, onCreate, footer, ...restProps } = props;

  const onSearchInput = useCallback((val: string) => {
    if (typeof onSearch === 'function') {
      onSearch(val);
    }
  }, [onSearch]);

  return <DataListWrapper>
    <TopLevelActions>
      {/* <Grid> */}
      <Row>
        <Col flex="auto">
          {typeof props.onSearch === 'function' ? <Input.Search onSearch={onSearchInput}></Input.Search> : null}
        </Col>
        <Col flex="100px" style={{ textAlign: 'right' }} >
          {typeof props.onCreate === 'function' ? <Button type="primary" onClick={props.onCreate}>CREATE</Button> : null}
        </Col>
      </Row>
      {/* </Grid> */}

    </TopLevelActions>
    <Table {...restProps} />
  </DataListWrapper>;
};