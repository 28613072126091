import { Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { getUserData, setUserData } from '../../utils';
import { StoreModel } from './store';

export interface StoreListeners {
  onLoginSuccess: ThunkOn<StoreModel, StoreModel>;
  onSetToken: ThunkOn<StoreModel, StoreModel>;
}

export const createStoreListeners = (): StoreListeners => {
  return {
    onLoginSuccess: thunkOn((actions => actions.auth.login.successType), (actions, target) => {
      if (!target.error) {
        actions.user.setUser({
          ...target.result,
        });
        setUserData(target.result);
        console.log('setUserData', target.result);
      }
    }),
    onSetToken: thunkOn((actions => actions.auth.setToken), (actions, target) => {
      const user = getUserData();
      setUserData({
        login: user ? user.login : '',
        id: user ? user.id : '',
        jwt: target.payload.token,
        exp: target.payload.expire,
      });
    }),
  };
};