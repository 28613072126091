import { Button, Form, Input, Select, Space } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import api from '../api';
import { useStoreActions, useStoreState, PostLinkPayload } from '../store';
// move external exports to models or types
import { UILink } from '../types';
import { FormWrapper } from './FormWrapper';

export interface CreateLinkProps {
  onCanceled: () => void;
  onCreated: (link: UILink) => void;
}

export const CreateLink: FC<CreateLinkProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const campaigns = useStoreState(state => state.campaigns.campaigns);
  const { postLink } = useStoreActions(actions => actions.links);
  const { getCampaigns } = useStoreActions(actions => actions.campaigns);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    // if (campaigns.length === 0) {
      getCampaigns();
      api.get('/tags').then((resp)=>{
        setTags(resp.data);
      });
    // }
  }, [getCampaigns]);

  const onFinish = useCallback((data: PostLinkPayload) => {
    setLoading(true);

    postLink(data).then((link) => {
      if (typeof props.onCreated === 'function') {
        props.onCreated(link);
      }

    }).finally(() => {
      setLoading(false);
    });

  }, [setLoading, postLink, props]);

  return <FormWrapper>
    <Form onFinish={onFinish} labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}>
      <Form.Item name="longUrl" label="Long URL" required={true}>
        <Input placeholder="Enter Long URL" />
      </Form.Item>
      <Form.Item name="label" label="Label" required={true}>
        <Input placeholder="Enter Link Label" />
      </Form.Item>
      <Form.Item name="campaign_id" label="Campaign" required={false}>
        <Select allowClear={true} placeholder='Assign Campaign'>
          <Select.Option key={'no-value'} value={''}>Not specified</Select.Option>
          <Select.OptGroup label="Campaigns">
            {campaigns.map((campaign) => {
              return <Select.Option key={campaign.id} value={campaign.id}>{campaign.name}</Select.Option>;
            })}
          </Select.OptGroup>
        </Select>
      </Form.Item>
      <Form.Item name='tags' label='Tags' required={false}>
        <Select
          mode="tags"
          // style={{ width: '100%' }}
          placeholder="Tags"
          // onChange={handleChange}
          options={tags.map((tag)=>{ return { value: tag, label: tag} })}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button disabled={loading} onClick={props.onCanceled}>CANCEL</Button>
          <Button htmlType="submit" type="primary" loading={loading}>SHORTEN</Button>
        </Space>
      </Form.Item>
    </Form>
  </FormWrapper>;
};