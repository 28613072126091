import _ from 'lodash';

import { UILink, UserData } from './types';

export const setUserData = (user: UserData) => {
    window.localStorage.setItem('xslink_user', JSON.stringify(user));
};

export const getUserData = (): UserData | null => {
    // let userData: UserData = { "id": "some", "login": "catronex", "jwt": "jwt", "exp": 99999999999 };
    try {
        const user = JSON.parse(window.localStorage.getItem('xslink_user') || '');
        if (!user.jwt) {
            return null;
            // throw new Error('Token not found');
        }
        return user;
    } catch (e) {
        console.log(e);
        // if (window.PROD) {
        //     window.location.href = "/";
        // }
    }
    return null;
};


export const getUserToken = (): string => {
    const user = getUserData();
    if (user) {
        return user.jwt || '';
    }
    return '';
};

const basePath = process.env.NODE_ENV === 'production' ? 'https://lee.tl' : 'http://localhost:3000';

export const getLongURL = (link: UILink) => {
    const main = _.first(link.redirect.main.urls || []);
    return main ? main.url || '' : '';
};

export const getShortURL = (link: UILink) => {
    return `${basePath}/c/${link.key}`;
};

export const getDisplayShortURL = (link: UILink) => {
    return getShortURL(link).replace('https://', '').replace('http://', '');
};
