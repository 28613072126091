import api from './base';

export interface LoginResponse {
  id: string;
  login: string;
  api_key: string;
  jwt: string;
  exp: number;
}

export interface CreateUserPayload {
  email: string;
  password: string;
}

export const createUser = async (data: CreateUserPayload): Promise<LoginResponse> => {
  const resp = await api.post('/auth/signup', data);
  return resp.data;
};