import { CopyOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React, { FC, useState, useCallback } from "react";
import CopyToClipboard from 'react-copy-to-clipboard';

interface CopyProps {
    style?: React.CSSProperties;
    text: string;
    title?: string;
    onCopy?: () => void;
    children?: React.ReactNode;
}

const Copy: FC<CopyProps> = (props) => {
    // const [copied, setCopied] = useState(false);
    // const [messageApi, contextHolder] = message.useMessage();

    const onCopy = useCallback(() => {
        
        if (typeof props.onCopy === 'function') {
            props.onCopy();
        }
        message.success('Copied!');
        
    }, [props]);

    return <Button icon={<CopyOutlined/>}>
        {/* {contextHolder} */}
        <CopyToClipboard text={props.text} onCopy={onCopy} >
          <span>{props.children ? props.children : 'Copy'}</span>
        </CopyToClipboard>
    </Button>
}

export default Copy;