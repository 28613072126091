import { Button, Form, Input, Space } from 'antd';
import React, { FC, useCallback, useState } from 'react';
import { useStoreActions } from '../store';
import { Campaign } from '../types';
import { FormWrapper } from './FormWrapper';

export interface CreateCampaignProps {
  onCreated: (campaign: Campaign) => void;
  onCanceled: () => void;
  // children: React.ReactNode;
}

export const CreateCampaign: FC<CreateCampaignProps> = (props) => {
  const { postCampaign } = useStoreActions(actions => actions.campaigns);
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback((values: { name: string; }) => {
    setLoading(true);

    // TODO add error handling
    postCampaign(values)
      .then((campaign) => {
        if (typeof props.onCreated === 'function') {
          props.onCreated(campaign);
        }
      })
      .finally(() => {
        setLoading(false);
      });

  }, [postCampaign, setLoading, props]);

  return <FormWrapper>
    <Form labelCol={{span: 6}} wrapperCol={{span: 18}} onFinish={onFinish}>
      <Form.Item label="Name" name="name" required={true} >
        <Input />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button disabled={loading} onClick={props.onCanceled}>CANCEL</Button>
          <Button loading={loading} type="primary" htmlType="submit">CREATE</Button>
        </Space>
      </Form.Item>
    </Form>
  </FormWrapper>;
};

// export interface CreateCampaignModalProps extends CreateCampaignProps {
//   visible: boolean;
//   onCancel: ()=>void;
// }

// export const CreateCampaignModal = ()=>{
//   return <Modal visible={} on >
//     <CreateCampaign />
//   </Modal>
// }