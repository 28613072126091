import axios from "axios";
import { getUserToken } from "../utils";

export const baseURL = process.env.NODE_ENV === 'production' ? 'https://api.xslink.io' : 'http://localhost:3000';

const http = axios.create({
  baseURL: baseURL,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': "application/json",
    "X-REQUESTED-BY": "dashboard.xslink.io",
  },
});

http.interceptors.request.use(
  async (config) => {
    const token = getUserToken(); // can be async
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
