import { Button, Card, Form, Input, message, Space } from 'antd';
import { FC, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FormWrapper } from '../components';
import { SignUpPayload, useStoreActions, useStoreState } from '../store';

const SignUpWrapper = styled.div``;

const SignUpBox = styled.div`
  position: absolute;
  left: 50%;
  top: 25%;
  margin-left: -200px;
  width:400px;
`;


export const SignUpPage: FC = () => {
  const signUpAction = useStoreActions(actions => actions.auth.signup);
  const data = useStoreState((state)=>state.auth);
  const navigate = useNavigate();
  const onFinish = useCallback((values:SignUpPayload)=>{
    const createUser = async()=>{
      await signUpAction(values);
      message.success('Account Created');
      navigate('/');
    };

    createUser();
  }, []);

  return <SignUpWrapper>
    <SignUpBox>
      <Card title="XSLINK SignUp">
        <FormWrapper>
          <Form onFinish={onFinish}>
            <Form.Item required name='email'>
              <Input type='email' placeholder='Email' />
            </Form.Item>
            <Form.Item required name='password'>
              <Input.Password placeholder='Password' />
            </Form.Item>
            <Form.Item required name='confirm' dependencies={['password']}>
              <Input.Password placeholder='Confirm Password' />
            </Form.Item>
            <Form.Item style={{ textAlign: 'right' }}>
              <Space size='large'>
                <Link to='/login'>Login</Link>
                <Button type='primary' htmlType='submit'>Create Account</Button>
              </Space>
              
            </Form.Item>
          </Form>
        </FormWrapper>
      </Card>
    </SignUpBox>
  </SignUpWrapper>
}

export default SignUpPage;
