import { Button, Form, Input, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import _ from 'lodash';
import { FC, useCallback, useState } from 'react';

import { UpdateLinkPayload, useStoreActions } from '../store';
// move external exports to models or types
import { Campaign } from '../types';
import { FormWrapper } from './FormWrapper';

export interface UpdateCampaignProps {
  onCanceled: () => void;
  onUpdated: (campaign: Campaign) => void;
  campaign?: Campaign;
}

export const UpdateCampaign: FC<UpdateCampaignProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const { pathCampaign } = useStoreActions(actions => actions.campaigns);
  const [form] = useForm();


  const onFinish = useCallback((data: UpdateLinkPayload) => {
    setLoading(true);
    const values = form.getFieldsValue();

    if (props.campaign && props.campaign.id) {
      for (let key in values) {
        if (_.isEqual(values[key], (props.campaign as any)[key])) {
          delete values[key];
        }
      }
      pathCampaign({ ...values, id: props.campaign.id || '' }).then((campaign) => {
        if (typeof props.onUpdated === 'function') {
          props.onUpdated(campaign);
        }

      }).finally(() => {
        setLoading(false);
      });
    }
  }, [setLoading, pathCampaign, props, form]);

  if (!props.campaign) {
    return null;
  }

  return <FormWrapper>
    <Form onFinish={onFinish} labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }} form={form} >
      <Form.Item label="ID">
        {props.campaign.id}
      </Form.Item>
      <Form.Item name="name" label="Name" required={true}>
        <Input placeholder="Enter Campaign Name" defaultValue={props.campaign.name} />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button disabled={loading} onClick={props.onCanceled}>CANCEL</Button>
          <Button htmlType="submit" type="primary" loading={loading}>UPDATE</Button>
        </Space>
      </Form.Item>
    </Form>
  </FormWrapper>;
};