import { FolderOutlined, LinkOutlined, LogoutOutlined, PlusOutlined, SettingFilled } from '@ant-design/icons';
import {
  Col, ConfigProvider, Layout,
  Menu, message, Modal
} from "antd";
import 'antd/dist/reset.css';
import { StoreProvider } from "easy-peasy";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { AppLayout } from './AppLayout';
import { CreateLink } from './components';
import { DEFAULT_PAGE } from './config';
import { CampaignDetailsPage, CampaignsPage, LinksPage, LoginPage, LogoutPage } from './pages';
import SignUpPage from './pages/Signup';
import { Store, useStoreState } from './store';


const { Header, Footer, Sider, Content } = Layout;

export const AppRender = () => {
  const location = useLocation();
  
  const defaultSelectedMenuItem = _.first(location.pathname.substring(1).split('/')) || '';

  const [showCreateModal, setShowCreateModal] = useState(false);

  const onCreate = useCallback(() => {
    setShowCreateModal(true);
  }, [setShowCreateModal]); 

  const closeModal = useCallback(() => {
    setShowCreateModal(false);
  }, [setShowCreateModal]);


  return <Layout>
    <Header>
      <Col span={4}>
        <div
          className="logo"
          style={{
            float: "left",
            color: "white",
            fontSize: 24,
            marginLeft: 20,
          }}
        >
          <a href="/">xs LINK</a>
        </div>
      </Col>
      <Col span={16}>

      </Col>
      <Col span={4}>
        <div className="account_dropdown" style={{ float: "right" }}>
          {/*  */}
        </div>
      </Col>
    </Header>
    <Layout>
      <Modal open={showCreateModal} onCancel={closeModal} footer={null} title="Create Short Link">
        <CreateLink onCreated={closeModal} onCanceled={closeModal} />
      </Modal>
      <Sider width={200} style={{ background: "#f0f2f5" }}>
        <Menu
          mode="vertical"
          defaultSelectedKeys={[defaultSelectedMenuItem]}
          defaultOpenKeys={[defaultSelectedMenuItem]}
          style={{
            height: "100%",
            borderRight: 0,
            background: "#f0f2f5",
          }}
        >
          <Menu.Item icon={<PlusOutlined/>} onClick={onCreate}>
            Create New
          </Menu.Item>
          <Menu.Item key="links" icon={<LinkOutlined />}>
            <Link to="/links">Links</Link>
          </Menu.Item>
          <Menu.Item key="campaigns" icon={<FolderOutlined />}>
            <Link to="/campaigns">Campaigns</Link>
          </Menu.Item>
          <Menu.Item disabled={true} key="3" icon={<SettingFilled />}>
            Settings
          </Menu.Item>
          <Menu.Item key='logout' icon={<LogoutOutlined />}>
            <Link to="/logout">Logout</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Content>
        <Routes>
          <Route path="/links" element={<LinksPage />}/>
          <Route path="/campaigns" element={<CampaignsPage />}/> 
          <Route path="/campaigns/:id" element={<CampaignDetailsPage />} />
        </Routes>
      </Content>
    </Layout>
    <Footer
      style={{
        backgroundColor: "#00ABFA",
        color: "white",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <span style={{ fontWeight: "bold" }}>XS LINK™</span> ©{moment().format('yyyy')} Developed & Maintained
      by{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "white",
          textDecoration: "underline",
          fontWeight: "bold",
        }}
        href="https://igodev.com"
      >
        IGODEV LLC
      </a>
      <div style={{textAlign:'right'}}>
        <span title={moment(window.build_time).format()}>{window.version}</span>
      </div>
    </Footer>
  </Layout>;
};

const Router = ()=>{
  const location = useLocation();
  const navigate = useNavigate();

  const auth = useStoreState(state => state.auth.auth);

  useEffect(() => {
    if (!auth.token || moment(auth.expire || 0).isBefore()) {
      console.log('Invalid token data', auth.token, auth.expire);
      const to = `/login?redirect=${location.pathname}`;
      navigate(to);
      return;
    }
    if (location.pathname === '/') {
      navigate(DEFAULT_PAGE);
      return;
    }
  }, [auth, location, navigate]);
  return <Routes>
    <Route path="/*" element={<AppRender />} />
    <Route path="/" element={<AppRender />} />
  </Routes>;
};

export const NewApp = () => {
  const [, contextHolder] = message.useMessage();  
  return <StoreProvider store={Store}>
    <BrowserRouter>
      <ConfigProvider>
        {contextHolder}
        <AppLayout>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path='/logout' element={<LogoutPage />} />
            <Route path='/singup' element={<SignUpPage />} />
            <Route path='/*' element={<Router/>}/>
          </Routes>
        </AppLayout>
      </ConfigProvider>
    </BrowserRouter>
  </StoreProvider>;
};

export default NewApp;
