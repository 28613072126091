import { Button, Form, Input, Select, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { FC, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import api from '../api';
import { useStoreActions, useStoreState, UpdateLinkPayload } from '../store';
// move external exports to models or types
import { UILink } from '../types';
import { getLongURL } from '../utils';
import { FormWrapper } from './FormWrapper';

export interface UpdateLinkProps {
  onCanceled: () => void;
  onUpdated: (link: UILink) => void;
  link?: UILink;
}

export const UpdateLink: FC<UpdateLinkProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const campaigns = useStoreState(state => state.campaigns.campaigns);
  const { patchLink } = useStoreActions(actions => actions.links);
  const { getCampaigns } = useStoreActions(actions => actions.campaigns);
  const [form] = useForm();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    // if (campaigns.length === 0) {
      getCampaigns();
      api.get('/tags').then((resp)=>{
        setTags(resp.data);
      });
    // }
  }, [getCampaigns]);

  const onFinish = useCallback((data: UpdateLinkPayload) => {
    setLoading(true);
    const values = form.getFieldsValue();
    
    if (props.link && props.link.id) {
      for (let key in values) {
        if (_.isEqual(values[key], (props.link as any)[key])) {
          delete values[key];
        }
      }
      if(!values.campaign_id) {
        values.campaign_id = '';
      }
      patchLink({ ...values, id: props.link.id || '' }).then((link) => {
        if (typeof props.onUpdated === 'function') {
          props.onUpdated(link);
        }

      }).finally(() => {
        setLoading(false);
      });
    } 
  }, [setLoading, patchLink, props, form]);

  if(!props.link) {
    return null;
  }

  return <FormWrapper>
    <Form onFinish={onFinish} labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }} form={form} >
        <Form.Item label="ID">
        {props.link.id}
        </Form.Item>
      <Form.Item name="longUrl" label="Long URL" required={true}>
        <Input placeholder="Enter Long URL" defaultValue={getLongURL(props.link)} />
      </Form.Item>
      <Form.Item name="label" label="Label" required={true}>
        <Input placeholder="Enter Link Label" defaultValue={props.link.label} />
      </Form.Item>
      <Form.Item name="campaign_id" label="Campaign" required={false}>
        <Select allowClear={true} placeholder='Assign Campaign' defaultValue={props.link.campaign_id}>
          <Select.Option key={'no-value'} value={''}>Not specified</Select.Option>
          <Select.OptGroup label="Campaigns">
            {campaigns.map((campaign) => {
              return <Select.Option key={campaign.id} value={campaign.id}>{campaign.name}</Select.Option>;
            })}
          </Select.OptGroup>
        </Select>
      </Form.Item>
      <Form.Item name='tags' label='Tags' required={false}>
        <Select
          mode="tags"
          // style={{ width: '100%' }}
          placeholder="Tags"
          // onChange={handleChange}
          options={tags.map((tag)=>{ return { value: tag, label: tag} })}
          defaultValue={props.link.tags}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button disabled={loading} onClick={props.onCanceled}>CANCEL</Button>
          <Button htmlType="submit" type="primary" loading={loading}>UPDATE</Button>
        </Space>
      </Form.Item>
    </Form>
  </FormWrapper>;
};