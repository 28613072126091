import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { NewApp } from './App';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
if (!container) {
  throw new Error('root not found');
}
const root = ReactDOM.createRoot(container);

root.render(<NewApp/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
