import { Col, Drawer, Empty, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';

import { DataList, LinkDetails, UpdateLink } from '../components';
import { useStoreActions, useStoreState } from '../store/index';
import { UILink } from '../types';
import { getDisplayShortURL } from '../utils';

export const LinksPage: FC = () => { 
  const { getLinks } = useStoreActions((state) => state.links);
  const { links } = useStoreState(state => state.links);
  const { getLinksStats } = useStoreActions((state) => state.stats);
  const { linksStats } = useStoreState(state=>state.stats);
  const [selectedLink, setSelectedLink] = useState<UILink>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [editMode, setEditMode] = useState(false);

  const onEditClose = useCallback(() => {
    setEditMode(false);
  }, []);

  const onEdit = useCallback(() => {
    setEditMode(true);
  }, []);


  useEffect(() => {
    getLinks({ limit: 1000, page: 0, });
    getLinksStats();
  }, [getLinks, getLinksStats]);

  useEffect(()=>{
    if (links && links[0]){
      setSelectedLink(links[0]);
      setSelectedRowKeys([links[0].id || '']);
    }
  }, [links])


  // const onShowStatsClick = useCallback((link:UILink)=>{
  //   setSelectedLink(link);
  //   setShowStatsModal(true);
  // }, [setSelectedLink, setShowStatsModal]);

  const columns: ColumnProps<UILink>[] = [
    {
      title: "All Links",
      render: (link: UILink) => {
        return <>
          <Row>{moment(link.updated_at).format('MMMM DD, YYYY')}</Row>
          <Row>
            <Col span={21} style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {link.label}
            </Col>
            <Col span={3}>
            </Col>
          </Row>
          <Row>
            <Col span={9}>
              {getDisplayShortURL(link)}
            </Col>
            <Col span={12}></Col>
            <Col span={3}>
              {linksStats[link.key]?.totalClicks || 0}
            </Col>
          </Row>
        </>
      },
      key: "short_url",
      ellipsis: true,
    },
  ];

  return <>
    <Row>
      <Drawer width={500} open={editMode} onClose={onEditClose}>
        <UpdateLink link={editMode ? selectedLink : undefined} onCanceled={onEditClose} onUpdated={onEditClose} /> 
      </Drawer>
      <Col span={8}>
        <DataList 
          rowSelection={{
            type: 'radio',
            onChange: setSelectedRowKeys,
            onSelect: setSelectedLink,
            selectedRowKeys,
            renderCell: ()=>null,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (record && record.id) {
                  setSelectedRowKeys([record.id]);
                  setSelectedLink(record);
                }
              }, // click row
              onDoubleClick: (event) => { }, // double click row
              onContextMenu: (event) => { }, // right button click row
              onMouseEnter: (event) => { }, // mouse enter row
              onMouseLeave: (event) => { }, // mouse leave row
            };
          }}
         size="small"
         dataSource={links}
         rowKey={'id'}
         columns={columns}
        //  onCreate={onCreate} 
         />
      </Col>
      <Col span={15}>
          <div style={{paddingTop: 32}}>
          {selectedLink ? <LinkDetails link={selectedLink} onEdit={onEdit} /> : <Empty/>}
          </div>
      </Col>
      <Col span={1}></Col>
    </Row>

    
  </>;
};

export default LinksPage;