import { action, Action, thunk, Thunk } from 'easy-peasy';
import { StoreInjections, StoreModel } from './store';

export interface LinkStats { totalClicks: number, uniqueClicks: number; }

export interface LinksStats {
  [key: string]: Partial<LinkStats>;
}

export interface SetLinkStatsPayload {
  key: string;
  data: Partial<LinkStats>;
}

export interface CampaignStats {
  totalClicks: number;
  uniqueClicks: number;
}
export interface CampaignsStats {
  [key: string]: Partial<CampaignStats>;
}

export interface SetCampaignStatsPayload {
  id: string;
  data: Partial<CampaignStats>;
}

export interface StatsModel {
  linksStats: LinksStats;
  campaignsStats: CampaignsStats;
  setLinksStats: Action<StatsModel, LinksStats>;
  setLinkStats: Action<StatsModel, SetLinkStatsPayload>;
  getLinksStats: Thunk<StatsModel, never, StoreInjections, StoreModel, Promise<LinksStats>>;
  getLinkStats: Thunk<StatsModel, string, StoreInjections, StoreModel, Promise<Partial<LinkStats>>>;
  setCampaignStats: Action<StatsModel, SetCampaignStatsPayload>;
  getCampaignStats: Thunk<StatsModel, string, StoreInjections, StoreModel, Promise<Partial<CampaignStats>>>;
}


export const createStatsModel = (): StatsModel => {
  return {
    linksStats: {},
    campaignsStats: {},
    setLinksStats: action((state, payload) => {
      state.linksStats = payload;
    }),
    setLinkStats: action((state, payload) => {
      for (const key in payload.data) {
        if (Object.prototype.hasOwnProperty.call(payload.data, key)) {
          const value = payload.data[key as keyof LinkStats] || 0;
          state.linksStats[payload.key][key as keyof LinkStats] = value;
        }
      }
    }),
    getLinksStats: thunk(async (actions, payload, { injections: { api } }) => {
      const resp = await api.get('/stats/links');
      const map: LinksStats = {};
      resp.data.forEach((stats: { key: string, count: number; }) => {
        map[stats.key] = { totalClicks: stats.count };
      });
      actions.setLinksStats(map);
      return map;
    }),
    getLinkStats: thunk(async (actions, id, { injections: { api } }) => {
      const resp = await api.get<{ key: string, uniqueClicks: number, clicks: number; }>(`/stats/links/${id}`);
      const linkStats = {
        totalClicks: resp.data.clicks,
        uniqueClicks: resp.data.uniqueClicks,
      };
      actions.setLinkStats({ key: resp.data.key, data: linkStats });
      return linkStats;
    }),
    getCampaignStats: thunk(async (actions, campaignID, { injections: { api } }) => {
      const resp = await api.get(`/stats/campaigns/${campaignID}`);
      const stats = {
        totalClicks: resp.data.total_clicks,
        uniqueClicks: resp.data.unique_clicks,
      };
      actions.setCampaignStats({
        id: campaignID,
        data: stats,
      });
      return stats;
    }),
    setCampaignStats: action((state, payload) => {
      state.campaignsStats[payload.id] = payload.data;
    }),
  };
};