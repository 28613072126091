import { action, Action, thunk, Thunk } from 'easy-peasy';
import _ from 'lodash';

import { StoreInjections, StoreModel } from './store';

export interface AuthUser {
  login: string;
  password: string;
}

export interface SignUpPayload {
  email: string;
  password: string;
}

export interface ApiUser {
  id: string;
  jwt: string;
  exp: number;
  api_key: string;
  login: string;
}

export interface AuthToken {
  token: string;
  expire: number;
}

export interface AuthModel {
  auth: AuthToken;

  setToken: Action<AuthModel, AuthToken>;
  // refreshToken: Thunk<AuthModel, AuthUser, StoreInjections, StoreModel>;

  login: Thunk<AuthModel, AuthUser, StoreInjections, StoreModel>;
  signup: Thunk<AuthModel, SignUpPayload, StoreInjections, StoreModel>;
}

// const thunkBuilder = (hadnler) => {
//   return {
//     loading: false,
//     setLoading: action((state, isLoading) => {
//       state.loading = isLoading;
//     }),
//     error: null,
//     setError: action((state, error) => {
//       state.error = error;
//     }),
//     action: thunk(async (actions, payload, helpers) => {
//       actions.setError(null);
//       actions.setLoading(true);
//       try {
//         const result = await handler(actions, payload, helpers);
//         actions.setLoading(false);
//       } catch (error) {
//         actions.setLoading(false);
//         actions.setError(error);
//       }
//       return result;
//     }),
//   };
// };

// const test = {
//   login: 
// };

export const createAuthModel = (): AuthModel => {
  let auth = null;
  try {
    auth = JSON.parse((localStorage.getItem('xslink_auth') || '{}'));
  } catch (e) {
    console.error('Error while parsing auth', e);
  }
  auth = _.defaults(auth, {
    token: '',
    expire: 1,
  });

  return {
    auth,
    setToken: action((state, payload) => {
      state.auth = payload;
      localStorage.setItem('xslink_auth', JSON.stringify(payload));
    }),
    // refreshToken: thunk((actions, payload, { injections: { api } }) => {
    //   return api.post()
    // }),
    login: thunk(async (actions, payload, { injections: { api } }) => {
      try {
        const resp = await api.post('/auth/login', payload);
        if (resp.status !== 200) {
          throw new Error('Invalid credentials');
        }
        const user: ApiUser = resp.data;
        actions.setToken({
          token: user.jwt,
          expire: user.exp,
        });
        return user;
      } catch (err) {
        if (err) {
          console.error(err);
        }
      }
    }),

    signup: thunk(async (actions, payload, { injections: { createUser } }) => {
      const user = await createUser(payload);
      actions.setToken({
        token: user.jwt,
        expire: user.exp,
      });
    })
  };
};