import { createStore } from 'easy-peasy';
import { getUserData } from '../utils';
import api, { createUser } from "../api";
import { createAuthModel, createCampaignModel, createLinksModel, createStatsModel, createStoreListeners, createUserModel, StoreModel } from './model';

const storeData: StoreModel = {
  links: createLinksModel(),
  campaigns: createCampaignModel(),
  user: createUserModel(),
  auth: createAuthModel(),
  stats: createStatsModel(),
  ...createStoreListeners(),
};

const sessionUserData = getUserData();

export const Store = createStore(storeData, {
  injections: { api, createUser },
  initialState: {
    auth: {
      auth: {
        token: sessionUserData?.jwt,
        expire: sessionUserData?.exp,
      },
    },
  },
});
export * from './hooks';
export * from './model';

