import { action, Action, thunk, Thunk } from 'easy-peasy';
import _ from 'lodash';
import { UILink } from '../../types';
import { StoreInjections, StoreModel } from './store';

export interface GetLinksPayload {
  page: number;
  limit: number;
}

export type GetLinkPayload = String;
export interface UpdateLinkPayload {
  id: string;
  tags?: string[];
  label?: string;
  campaign_id?: string;
}

export interface PostLinkPayload {
  longUrl: string;
  label: string;
}

export interface LinksModel {
  links: UILink[];
  setLinks: Action<LinksModel, UILink[]>;
  setLink: Action<LinksModel, Partial<UILink>>;
  getLinks: Thunk<LinksModel, GetLinksPayload, StoreInjections, StoreModel, Promise<UILink[]>>;
  getLink: Thunk<LinksModel, GetLinkPayload, StoreInjections, StoreModel, Promise<UILink>>;
  patchLink: Thunk<LinksModel, UpdateLinkPayload, StoreInjections, StoreModel, Promise<UILink>>;
  postLink: Thunk<LinksModel, PostLinkPayload, StoreInjections, StoreModel, Promise<UILink>>;
}

export const createLinksModel = (): LinksModel => {
  return {
    links: [],
    getLinks: thunk((actions, payload, { injections: { api } }) => {
      return api.get('/links').then((resp) => {
        actions.setLinks(resp.data);
        return resp.data;
      }).catch((err) => {
        console.error(err);
      });
    }),
    getLink: thunk((actions, id, { injections: { api } }) => {
      return api.get(`/links/${id}`).then((resp) => {
        return resp.data;
      }).catch((err) => {
        console.error(err);
      });
    }),
    patchLink: thunk((actions, payload, { injections: { api } }) => {
      return api.patch(`/links/${payload.id}`, {
        ...payload,
      }).then((resp) => {
        actions.setLink(resp.data);
        return resp.data;
      }).catch((err) => {
        console.error(err);
      });
    }),

    postLink: thunk(async (actions, payload, { injections: { api } }) => {
      const resp = await api.post('/generate/short', payload);
      const link = await actions.getLink(resp.data.id);
      actions.setLink(link);
      return link;
    }),

    setLink: action((state, payload) => {
      const index = _.findIndex(state.links, (l) => {
        return l.id === payload.id;
      });
      if (index === -1) {
        state.links.push({
          ...payload,
          features: payload.features || [],
          // TODO add current user from store
          // @ts-ignore 
          user: payload.user || null,
        });
        return;
      }

      state.links[index] = {
        ...state.links[index],
        ...payload,
      };

    }),
    setLinks: action((state, payload) => {
      state.links = payload || [];
    }),
  };
};