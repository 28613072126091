import { action, Action } from 'easy-peasy';


const initialUser = { id: '', api_key: '' };

export interface StoreUser {
  id: string, api_key: string;
}

export interface UserModel {
  user: StoreUser;
  setUser: Action<UserModel, StoreUser>;
}

export const createUserModel = (): UserModel => {
  return {
    user: initialUser,
    setUser: action((state, payload) => {
      state.user = payload;
    }),
  };
};