import { Button, Card, Divider, Form, Input, Space } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { useCallback } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FormWrapper } from '../components';
import { DEFAULT_PAGE } from '../config';
import { useStoreActions, useStoreState } from '../store';

const LoginWrapper = styled.div`

`;

const LoginBox = styled.div`
  position: absolute;
  left: 50%;
  top: 25%;
  margin-left: -200px;
  width:400px;
`;

export const LoginPage = () => {
  const location = useLocation();

  const loginAction = useStoreActions(actions => actions.auth.login);
  const { token, expire } = useStoreState(state => state.auth.auth);

  const onLoginClick = useCallback((values:{password:string, login:string}) => {
    loginAction(values);
  }, [loginAction]);

  if (token && moment(expire).isAfter()) {
    const search = queryString.parse(location.search);
    const redirect = search.redirect ?
      Array.isArray(search.redirect) ?
        _.first(search.redirect) || DEFAULT_PAGE :
        search.redirect :
      DEFAULT_PAGE;
    return <Navigate to={redirect} />;
  }

  return <LoginWrapper>
    <LoginBox>
      <Card title="XSLINK Login">
        <FormWrapper>
          <Form onFinish={onLoginClick}>
            <Form.Item required name='login'>
              <Input placeholder="Login"></Input>
            </Form.Item>
          <Form.Item required name='password'>
            <Input.Password placeholder='Password'></Input.Password>
          </Form.Item>
          <Divider />
            <Form.Item style={{ textAlign: 'right' }}>
            <Space size='large'>
                <Link to='/singup'>Sign Up</Link>
                <Button type="primary" htmlType="submit">Login</Button>
            </Space>
          </Form.Item>
        </Form>
        </FormWrapper>
      </Card>
    </LoginBox>
  </LoginWrapper>;
};