import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding:16px;
`;

export interface FormWrapperProps {
  children: React.ReactNode;
}

export const FormWrapper: FC<FormWrapperProps> = (props) => {
  return <Wrapper>
    {props.children}
  </Wrapper>;
};