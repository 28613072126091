import { Breadcrumb, Button, Card, Col, Drawer, Empty, Row, Space, Statistic } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import _ from 'lodash';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';


import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import { DataList, LinkDetails, UpdateCampaign, UpdateLink } from '../components';
import { useStoreActions, useStoreState } from '../store/index';
import { UILink } from '../types';
import { getDisplayShortURL } from '../utils';
import { EditOutlined } from '@ant-design/icons';

const BreadcrumbsSection = () => {
  const {id} = useParams();
  const resource:'campaigns' = 'campaigns';

  const resources = useStoreState(state=>state[resource][resource]);

  const exactResource = resources.find((r)=>{ return r.id = (id||'')  });

  const items:BreadcrumbItemType[] = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: _.capitalize(resource),
      path: `/${resource}`
    },
    {
      title: exactResource?.name,
      // path: `/${resource}/${id}`,
    },
  ];
  const itemRender: (route: any, params: any, routes: (any)[], paths: string[]) => React.ReactNode = (route, params, items, paths) => {
    const last = items.indexOf(route) === items.length - 1;
    return last ? <span>{route.title}</span> : <Link to={paths.join('/') || '/'}>{route.title}</Link>;
  }
  return <Row style={{paddingLeft: 40, paddingTop: 25}}>
    <Breadcrumb itemRender={itemRender} items={items}  />
  </Row>;
};

export const CampaignDetailsPage: FC = () => {
  const { id: campaignID } = useParams<{ id: string; }>();
  const { getCampaign } = useStoreActions((state) => state.campaigns);
  const { campaigns } = useStoreState(state=>state.campaigns);
  const { getLinksStats, getCampaignStats } = useStoreActions((state) => state.stats);
  const { linksStats, campaignsStats } = useStoreState(state => state.stats);

  const [links, setLinks] = useState<UILink[]>([]);
  const [selectedLink, setSelectedLink] = useState<UILink>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [linkEditMode, setLinkEditMode] = useState(false);
  const [campaignEditMode, setCampaignEditMode] = useState(false);
  

  const onLinkEditClose = useCallback(() => {
    setLinkEditMode(false);
  }, []);

  const onLinkEdit = useCallback(() => {
    setLinkEditMode(true);
  }, []);

  const onCampaignEditClose = useCallback(() => {
    setCampaignEditMode(false);
  }, []);

  const onCampaignEdit = useCallback(() => {
    setCampaignEditMode(true);
  }, []);



  useEffect(() => {
    if (campaignID) {
      getCampaign(campaignID).then((campaign:any)=>{
        setLinks(campaign.links)
      });
      getCampaignStats(campaignID);
      getLinksStats();
    }
  }, [campaignID, getCampaign, getLinksStats, getCampaignStats]);

  useEffect(() => {
    if (links && links[0]) {
      setSelectedLink(links[0]);
      setSelectedRowKeys([links[0].id || '']);
    }
  }, [links]);

  const columns: ColumnProps<UILink>[] = [
    {
      title: "Campaign Links",
      render: (link: UILink) => {
        return <>
          <Row>{moment(link.updated_at).format('MMMM DD, YYYY')}</Row>
          <Row>
            <Col span={21} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {link.label}
            </Col>
            <Col span={3}>
            </Col>
          </Row>
          <Row>
            <Col span={9}>
              {getDisplayShortURL(link)}
            </Col>
            <Col span={12}></Col>
            <Col span={3}>
              {linksStats[link.key]?.totalClicks || 0}
            </Col>
          </Row>
        </>;
      },
      key: "short_url",
      ellipsis: true,
    },
  ];

  if(!campaignID){
    return null;
  }

  const campaign = _.first(_.filter(campaigns, (c) => {
    return c.id === campaignID;
  }));

  if (!campaign){
    return null;
  }

  return <>
    <Drawer width={500} open={linkEditMode} onClose={onLinkEditClose}>
      <UpdateLink link={linkEditMode ? selectedLink : undefined} onCanceled={onLinkEditClose} onUpdated={onLinkEditClose} /> 
    </Drawer>
    <Drawer width={500} open={campaignEditMode} onClose={onCampaignEditClose}>
      <UpdateCampaign campaign={campaign} onCanceled={onCampaignEditClose} onUpdated={onCampaignEditClose} />
    </Drawer>
    <BreadcrumbsSection />
    <Card 
      title={`Summary Stats: ${campaign.name}`} 
      style={{ marginTop: 15, marginLeft: 30, marginRight: 75 }} 
      extra={<Button icon={<EditOutlined/>} onClick={onCampaignEdit}>EDIT</Button>} 
    >
      <Row>
        <Col span={6}>
          <Statistic title='Total Clicks' value={campaignsStats[campaignID]?.totalClicks || 0} />
        </Col>
        <Col span={6}>
          <Statistic title='Unique Clicks' value={campaignsStats[campaignID]?.uniqueClicks || 0} />
        </Col>
        <Col span={6}>
          <Statistic title='Clicks Today' value={0} />
        </Col>
        <Col span={6}>
          <Statistic title='Last 7 Days Clicks' value={0} />
        </Col>
      </Row>
    </Card>
    <Row>
      <Col span={8}>
        <DataList
          rowSelection={{
            type: 'radio',
            onChange: setSelectedRowKeys,
            onSelect: setSelectedLink,
            selectedRowKeys,
            renderCell: () => null,
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (record && record.id) {
                  setSelectedRowKeys([record.id]);
                  setSelectedLink(record);
                }
              }, // click row
              onDoubleClick: (event) => { }, // double click row
              onContextMenu: (event) => { }, // right button click row
              onMouseEnter: (event) => { }, // mouse enter row
              onMouseLeave: (event) => { }, // mouse leave row
            };
          }}
          size="small"
          dataSource={links}
          rowKey={'id'}
          columns={columns}
        //  onCreate={onCreate} 
        />
      </Col>
      <Col span={15}>
        <div style={{ paddingTop: 32 }}>
          {selectedLink ? <LinkDetails onEdit={onLinkEdit} link={selectedLink} /> : <Empty />}
        </div>
      </Col>
      <Col span={1}></Col>
    </Row>


  </>;
};

export default CampaignDetailsPage;