import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Descriptions, QRCode, Row, Space, Statistic, Tag, Typography } from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import api from '../api';
import Copy from '../Copy';
import { UILink } from '../types';
import { getLongURL, getShortURL } from '../utils';

const { Link } = Typography;

const downloadQRCode = () => {
  const canvas = document.getElementById('qrcode')?.querySelector<HTMLCanvasElement>('canvas');
  if (canvas) {
    const url = canvas.toDataURL();
    const a = document.createElement('a');
    a.download = 'QRCode.png';
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export interface LinkDetailsProps {
  link: UILink;
  onEdit: ()=>void;
}

export const LinkDetails: FC<LinkDetailsProps> = (props) => {
  const { link } = props;
  const [stats, setStats] = useState({
    uniqueClicks: 0,
    clicks: 0,
    today: 0,
    week: 0,
  });
  const [statsLoading, setStatsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setStatsLoading(true);
      const statsData = await api.get(`/stats/links/${link.id}`);
      setStats({
        uniqueClicks: statsData.data.uniqueClicks,
        clicks: statsData.data.clicks,
        today: 0,
        week: 0,
      });
      setStatsLoading(false);
    };
    fetchData();
  }, [link.id]);
  return <>

    <Space direction="vertical" size="middle" >
      <Card title={link.label} extra={<Button icon={<EditOutlined />} onClick={props.onEdit}>EDIT</Button>}>
        <Row>
          <Col span={20}>
            <Descriptions layout='vertical'>
              <Descriptions.Item label='Link'>
                <Link href={getLongURL(link)} target='_blank'>{getShortURL(link)}</Link>
              </Descriptions.Item>
              <Descriptions.Item label='Name'>{link.label}</Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={4} style={{textAlign:'right'}}>
            <Copy text={getShortURL(link)} />
          </Col>
        </Row>

      </Card>
      <Card title='Stats' loading={statsLoading}>
        <Row>
          <Col span={6}>
            <Statistic title='Total Clicks' value={stats.clicks} />
          </Col>
          <Col span={6}>
            <Statistic title='Unique Clicks' value={stats.uniqueClicks} />
          </Col>
          <Col span={6}>
            <Statistic title='Clicks Today' value={stats.today} />
          </Col>
          <Col span={6}>
            <Statistic title='Last 7 Days Clicks' value={stats.week} />
          </Col>
        </Row>
      </Card>
      <Card title='Details'>
        <Descriptions layout='vertical'>
          <Descriptions.Item label='QR CODE'>
            <div id='qrcode' style={{ textAlign: 'center' }}>
              <QRCode value={getShortURL(link)} />
              <Button type='primary' icon={<DownloadOutlined />} onClick={downloadQRCode}>Download</Button>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label='Tags'> {link.tags?.map((tag) => <Tag>{tag}</Tag>)}  </Descriptions.Item>
          <Descriptions.Item label='ID'>{link.id}</Descriptions.Item>
          <Descriptions.Item label='Created'>{moment(link.updated_at).format('lll')}</Descriptions.Item>
        </Descriptions>
      </Card>
    </Space>

  </>;
};

export default LinkDetails;
