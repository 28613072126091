import { Modal } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { CreateCampaign, DataList } from '../components';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../store';
import { Campaign } from '../types';

export const CampaignsPage = () => {
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const { getCampaigns } = useStoreActions(actions => actions.campaigns);
  const { campaigns } = useStoreState(state => state.campaigns);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  const onCreate = useCallback(() => {
    setShowCreateModal(true);
  }, [setShowCreateModal]);

  const closeCreateCampaignModal = useCallback(() => {
    setShowCreateModal(false);
  }, []);

  const onRowClick = useCallback((campaign: Campaign): any => {
    navigate(`/campaigns/${campaign.id}`);
  }, [navigate]);

  const columns: ColumnProps<Campaign>[] = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (name, record) => {
        return <>{name}</>;
      }
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 400,
      ellipsis: true,
    },
    {
      title: 'Actions',
      width: 200,
      render: () => {
        return <div></div>;
      }
    }
  ];

  return <>
    <Modal open={showCreateModal} onCancel={closeCreateCampaignModal} footer={null} title='Create New Campaign'>
      <CreateCampaign
        onCanceled={closeCreateCampaignModal}
        onCreated={closeCreateCampaignModal}
      />
    </Modal>
    <DataList
      rowKey="id"
      size="small"
      dataSource={campaigns}
      columns={columns}
      onCreate={onCreate}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            if (record && record.id) {
              onRowClick(record);
            }
          }, // click row
          onDoubleClick: (event) => { }, // double click row
          onContextMenu: (event) => { }, // right button click row
          onMouseEnter: (event) => { }, // mouse enter row
          onMouseLeave: (event) => { }, // mouse leave row
        };
      }}
    />
  </>;
};

export default CampaignsPage;