import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useStoreActions } from '../store';

export const LogoutPage = () => {
  const setToken = useStoreActions(actions => actions.auth.setToken);

  useEffect(() => {
    setToken({
      token: '',
      expire: 1,
    });
  }, []);

  // TODO Navigate to successfully logout page
  return <Navigate to="/" />;
};