import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import _ from 'lodash';
import { Campaign, UILink } from '../../types';
import { StoreInjections, StoreModel } from './store';

export interface PostCampaignPayload {
  name: string;
}

export interface CampaignsModel {
  campaigns: Campaign[],
  getCampaigns: Thunk<CampaignsModel, undefined, StoreInjections, StoreModel, Promise<Campaign[]>>;
  getCampaign: Thunk<CampaignsModel, string, StoreInjections, StoreModel, Promise<Campaign>>;
  postCampaign: Thunk<CampaignsModel, PostCampaignPayload, StoreInjections, StoreModel, Promise<Campaign>>;
  pathCampaign: Thunk<CampaignsModel, Partial<Campaign>, StoreInjections, StoreModel, Promise<Campaign>>;
  setCampaign: Action<CampaignsModel, Campaign>;
  setCampaigns: Action<CampaignsModel, Campaign[]>;
}

export const createCampaignModel = (): CampaignsModel => {
  return {
    campaigns: [],
    getCampaigns: thunk(async (actions, payload, { injections: { api } }) => {
      const resp = await api.get('/campaigns');
      actions.setCampaigns(resp.data);
      return resp.data;
    }),
    getCampaign: thunk(async (actions, campaignID, { injections: { api }, getStoreActions }) => {
      const allActions = getStoreActions();
      const resp = await api.get(`/campaigns/${campaignID}`) as any;
      actions.setCampaign({
        id: resp.data.id,
        name: resp.data.name,
        user_id: resp.data.user_id,
      });
      resp.data.links.forEach((link: UILink) => {
        allActions.links.setLink(link);
      });
      return resp.data;
    }),
    postCampaign: thunk(async (actions, payload, { injections: { api } }) => {
      const resp = await api.post('/campaigns', payload);
      actions.setCampaign(resp.data);
      return resp.data;
    }),
    pathCampaign: thunk(async (actions, payload, { injections: { api } }) => {
      const resp = await api.patch(`/campaigns/${payload.id}`, payload);
      // TODO make sure backend respond with campaign body
      actions.setCampaign(resp.data);
      return resp.data;
    }),
    setCampaign: action((state, payload) => {
      const index = _.findIndex(state.campaigns, (c) => {
        return c.id === payload.id;
      });
      if (index === -1) {
        state.campaigns.push(payload);
        return;
      }

      state.campaigns[index] = payload;
    }),
    setCampaigns: action((state, payload) => {
      state.campaigns = payload;
    }),
  };
};
